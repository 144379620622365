<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1 class="font-weight-bold">Introduce tu información de <span class="highlight">contacto</span></h1>
      <p>Permite a las empresas saber cómo pueden ponerse en contacto contigo.</p>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.lastName"
            :rules="nameRules"
            label="Apellido"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.firstName"
            :rules="nameRules"
            label="Nombre"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.address"
            label="Dirección"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.city"
            label="Ciudad"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.postal"
            label="Código Postal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.phone"
            label="Teléfono"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="cv.contact.email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col align="right">
          <v-btn :disabled="!valid" color="info" class="mr-4" @click="validate">
            Siguiente
            <v-icon right dark>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    valid: true,
    validNext: true,
    nameRules: [
      (v) => !!v || "El nombre es un campo obligatorio",
      (v) => (v && v.length > 3) || "El nombre debe tener más de 3 caracteres",
    ],
    emailRules: [
      (v) => !!v || "El e-mail es un campo obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El e-mail no es válido",
    ],
  }),

  methods: {
    ...mapActions(["setNextStep", "setContact"]),
    validate() {
      if (
        this.cv.contact.firstName !== "" &&
        this.cv.contact.lastName !== "" &&
        this.cv.contact.email !== ""
      ) {
        this.validNext = true;
        this.valid = true;
      } else {
        this.validNext = false;
        this.valid = false;
      }
      this.$refs.form.validate();
      if (this.valid && this.validNext) {
        this.setContact(this.cv.contact);
        this.$refs.form.resetValidation();
        this.setNextStep();
      }
    },
  },
  computed: {
    ...mapState(["cv"]),
  },
};
</script>

<style scoped>
  h1 { font-size: 3em;}
  p { font-size: 1.1em; margin-bottom: 3em;}
</style>
