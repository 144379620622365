<template>
  <div>
    <v-avatar size="80">
      <v-img v-if="cv.img!=''" width="100%" :src="cv.img"></v-img>
    </v-avatar>
    <v-file-input
      :rules="rules"
      small-chips
      truncate-length="15"
      accept="image/*"
      v-model="files"
      color="blue"
      @click:clear="deleteImage()"
      :prepend-icon="newImageOrUpload"
      placeholder="Elige una foto (max 1MB)"
      :show-size="1000"
    ></v-file-input>  
    <div justify="center">
        <v-dialog v-model="openDialog" persistent max-width="450">
          <v-card>
            <v-card-title class="text-h5"></v-card-title>
            <v-card-text>
               <div style="width: 400px; height:300px; border: 1px solid gray; display: inline-block;">
                  <vue-cropper
                    ref="cropper"
                    :guides="true"
                    :view-mode="2"
                    drag-mode="crop"
                    :auto-crop-area="0.5"
                    :min-container-width="250"
                    :min-container-height="180"
                    :background="true"
                    :rotatable="false"
                    :src="imgSrc"
                    alt="Source Image"
                    :img-style="{ 'width': '400px', 'height': '300px' }"
                    :aspect-ratio="1"
                  ></vue-cropper>
                </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error darken-1" text @click="openDialog=false,files=null">
                Cancelar
              </v-btn>
              <v-btn
                color="info darken-1"
                text
                @click="cropImage()"
              >
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    VueCropper
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      openDialog : false,
      IMAGE_MAX_SIZE:  1000000,
      files: null,
      rules: [
      (value) => !value || value.size < 1000000 || `¡La imagen debe ocupar menos de 1Mb!`
    ]
    };
  },
  watch: {
    files: function (val) {
      
      console.log("Value es of val in my Watcher is: ", val)
      console.log("Value es of file in my Watcher is: ", this.files)
      if(this.files !== null){
        this.setImageDialog()
      }
      
    },
  },
  computed: {
    ...mapState(["cv"]),
    newImageOrUpload () {
      if (this.cv!=null && this.cv.img != null && this.cv.img.length >0){
        return "fas fa-redo-alt"
      }else    
        return "fas fa-camera";
    }
     
    
  },
  methods: {
     ...mapActions(["setImg"]),
    deleteImage(){
      this.imgSrc = ""
      this.cropImg = ""
      this.setImg(this.cropImg);
    },
    setImageDialog() {
      this.openDialog = true;
      if (this.files === null ) return ;
      if (this.files.size > this.IMAGE_MAX_SIZE) return
      
  
      const previsulizador = new FileReader();
      previsulizador.readAsDataURL(this.files);
      previsulizador.onload = e => {
        this.imgSrc= e.target.result
        this.$refs.cropper.replace( e.target.result);
      };
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.setImg(this.cropImg);
      this.openDialog = false;
    },
  }
};
</script>

<style scoped>
</style>
