<template>
  <div>
    <cv v-if="isMobileDevice"></cv>
    <Split v-else class="splitClass">
      <SplitArea id="buildingProcess">
        <cv></cv>
      </SplitArea>

      <SplitArea id="cvTemplate">
        <v-banner sticky elevation="5" single-line>
          <tool-bar></tool-bar>
        </v-banner>
        <hr id="limitA4"/>
        <component class="templateComponents" v-bind:is="currentTemplateComponent"></component>
      </SplitArea>
    </Split>
    <SliderLink />
    <Feedback />
  </div>
</template>

<script>
import Vue from "vue";
import SliderLink from "@/components/SliderLink"
import Feedback from "@/components/Feedback"
import VueSplit from "vue-split-panel";
import Cv from "../components/Cv.vue";
import TPrimary from "../components/TPrimary.vue";
import TDark from "../components/TDark.vue";
import {  mapState, mapActions } from 'vuex';
import TElegant from '../components/TElegant.vue';
import TSimple from '../components/TSimple.vue';
import ToolBar from '../components/ToolBar.vue';
import TModern from '../components/TModern.vue';
import TConcept from '../components/TConcept.vue';
import TCombined from '../components/TCombined.vue';
import TSublime from '../components/TSublime.vue';
import TAmbience from '../components/TAmbience.vue';
import TCascade from '../components/TCascade.vue';
import utilities from "../services/utilities"

Vue.use(VueSplit);
export default {
  data: () => ({
    width: 0,
    window : 0,
  }),
  name: "hacercurriculum",
  components: { 
    Cv, 
    TDark, 
    TPrimary, 
    TElegant, 
    TSimple,
    ToolBar, 
    TModern,
    TConcept,
    TCombined,
    TSublime,
    TAmbience,
    TCascade ,
    SliderLink,
    Feedback},
  methods: {
    ...mapActions(["updatePagesCv"]),
  },
  computed: {
    ...mapState(["cv", "isTwoPagesCv"]),
    isMobileDevice(){
      return utilities.isMobileDevice(this);
    },
    currentTemplateComponent(){
      console.log("CV SIZE: "+ this.$vuetify.breakpoint.name);
      console.log('CV FONTSIZE: ' + document.body.style.fontSize);
      return "t-" + this.cv.templateType.toLowerCase();
    }
  },
  watch: {
    isTwoPagesCv: function () {
      return utilities.updateHeight();
    },
    'cv.typeOfLetter': function () {
      return utilities.updateHeight();
    },
    'cv.sizeOfLetter': function () {
      utilities.setFontSizes(this, this.cv.sizeOfLetter);
      return window.setTimeout(utilities.updateHeight, 200);
    },
    'cv.align': function () {
      return utilities.updateHeight();
    },
  },
  mounted() {
    if (!utilities.isMobileDevice(this)){
      utilities.updateHeight();
    } 
    window.addEventListener("resize", utilities.updateHeight);
  },
};
</script>

<style scoped>
#limitA4 {
  position: relative;
  width: 85%;
  margin: auto;
  border: 1px dashed lightgrey;
  z-index: 100;
}
.templateComponents {
  background-color: white;
  margin: auto;
  margin-top: -8%;
  width: 100%;
  transform: scale(0.85, 0.85);
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
}
.splitClass {
  position: absolute;
  background-color: whitesmoke;
}
#cvTemplate {
  float: right;
}
#buildingProcess {
  float: left;
}
#cvTemplate .v-banner{
  background: white;
}
/*Scrollbar from SplitAreas*/
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(132, 131, 133);
  -webkit-border-radius: 10px;
}
</style>
