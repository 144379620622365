<template>
  <form @submit.prevent>
    <v-container id="print">
      <v-row>
        <v-col cols="12">
          <font size="7">
            <b>Mi CV</b>
          </font>
        </v-col>
        <v-col cols="6">
          <font size="5" class="highlight">
            <b>Contacto</b>
          </font>
          <br />
          Apellidos: {{ cv.contact.firstName }}
          <br />
          Nombre: {{ cv.contact.lastName }}
          <br />
          Dirección : {{ cv.contact.address }}
          <br />
          Ciudad : {{ cv.contact.city }}
          <br />
          Teléfono : {{ cv.contact.phone }}
          <br />
          Código Postal : {{ cv.contact.postal }}
          <br />
          Email : {{ cv.contact.email }}
          <br />
          <br />
        </v-col>

        <v-col cols="6" class="text-center">
         <editor></editor>
        </v-col>
        <v-col cols="12">
          <font size="5" class="highlight">
            <b>Experiencia</b>
          </font>
          <div>
            <v-simple-table dense v-if="cv.listExperience.length>0">
              <thead>
                <tr>
                  <th scope="col">Experiencia</th>
                  <th scope="col">Empresa</th>
                  <th scope="col">Fecha inicio y fin</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cv.listExperience" :key="index">
                  <td>
                    {{
                     item.job.length > 10
                    ? item.job.substring(0, 10) + "..."
                    : item.job
                    }}
                  </td>
                  <td>
                    {{
                     item.employer.length > 10
                    ? item.employer.substring(0, 10) + "..."
                    : item.employer
                    }}
                  </td>
                  <td>{{ item.dateStart ? formatDate (item.dateStart) : "..." }} ~ {{ item.checkbox ? "Presente" : formatDate (item.dateEnd) }}</td>
                  <td>
                    {{
                    item.city.length > 10
                    ? item.city.substring(0, 10) + "..."
                    : item.city
                    }}
                  </td>
                  <td>
                    {{
                    item.bio.length > 10
                    ? item.bio.substring(0, 10) + "..."
                    : item.bio
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <br />
        </v-col>
        <v-col cols="12">
          <font size="5" class="highlight">
            <b>Formación</b>
          </font>
          <div>
            <v-simple-table dense v-if="cv.listEducation.length>0">
              <thead>
                <tr>
                  <th scope="col">Escuela/Universidad</th>
                  <th scope="col">Título</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Ciudad</th>
                  <th scope="col">Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cv.listEducation" :key="index">
                  <td>
                    {{
                    item.nameSchool.length > 20
                    ? item.nameSchool.substring(0, 20) + "..."
                    : item.nameSchool
                    }}
                  </td>
                  <td>
                    {{
                    item.degree.length > 20
                    ? item.degree.substring(0, 20) + "..."
                    : item.degree
                    }}
                  </td>
                  <td>{{ item.date ? formatDate (item.date) : "..." }}</td>
                  <td>
                    {{
                    item.city.length > 20
                    ? item.city.substring(0, 20) + "..."
                    : item.city
                    }}
                  </td>
                  <td>
                    {{
                     item.bio.length > 20
                    ? item.bio.substring(0, 20) + "..."
                    : item.bio
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <br />
        </v-col>
        <v-col cols="12">
          <font size="5" class="highlight">
            <b>Aptitudes</b>
          </font>
          <div>
            <v-simple-table dense v-if="cv.listSkill.length>0">
              <thead>
                <tr>
                  <th scope="col">Aptitud</th>
                  <th scope="col">Nivel</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cv.listSkill" :key="index">
                  <td>
                    {{
                    item.name > 20
                    ? item.name.substring(0, 20) + "..."
                    : item.name
                    }}
                  </td>
                  <td>
                    <!-- <v-progress-linear :value=5 color="red"></v-progress-linear> -->
                    <div class="progress-concept">
                      <div :class="progressedValue(item.level)"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <br />
        </v-col>
        <v-col cols="12">
          <font size="5" class="highlight">
            <b>Bio</b>
          </font>
          <br />
          {{ cv.bio }}
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="white" class="mr-4" @click="goBack">
            <v-icon left dark>fas fa-arrow-left</v-icon>Volver
          </v-btn>
          <v-btn color="info" :disabled="loader" :loading="loader" @click="downloadCv">
            <v-icon left>fas fa-download</v-icon>PDF
          </v-btn>
        </v-col>
        <br />
      </v-row>
    </v-container>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from 'axios';
import Editor from './Editor.vue';

export default {
  components: { Editor },
  data: () => ({
    types:['Simple','Primary','Elegant','Dark'],
  }),
 
  computed: {
    ...mapState(["cv","loader"])
  },
  methods: {
    ...mapActions(["setBackStep", "setImg","setLoader","setTemplateType","updateCvInStorage"]),
    progressedValue(item) {
      return `progress-value-${item}-concept`;
    },
    trackEventOnGTM(){
      this.$gtm.trackEvent({
        event: null, // Event type [default = 'interaction'] (Optional)
        category: "CV download",
        action: this.cv.templateType,
        label: "",
        value: 1,
        noninteraction: false, // Optional
      });
    },
    goBack() {
      this.setBackStep();
    },
    
    changeTemplate(){
      this.updateCvInStorage();
    },
    async downloadCv() {
      this.setLoader(true);
      const {data} = await axios.post(
              '/api/cv:download',
              this.cv,
              {responseType: 'blob'}
      )
      this.setLoader(false);
      console.log(data);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cv.pdf');
      document.body.appendChild(link);
      link.click()
      link.remove();
      window.URL.revokeObjectURL(url);
      this.trackEventOnGTM()
    }
  },
  
};
</script>

<style lang="scss" scoped>
  #print thead tr th{text-transform: uppercase; color: $secondary;}
  #print .v-data-table {padding-top:2em;}
  .progress-concept div{background-color: $info;}
</style>
