<template>
  <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="">
    <v-container>
      <h1 class="font-weight-bold">Rellena la información relevante sobre tu <span class="highlight">formación</span></h1>
      <p>Primero introduce tu título más reciente.</p>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            color="info"
            outlined
            v-model="education.nameSchool"
            :rules="rules"
            label="Escuela/Universidad"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="education.degree"
            :rules="rules"
            label="Título/Formación"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="info"
                v-model="computedDateFormatted"
                label="Fecha de obtención"
                prepend-icon="far fa-calendar-alt"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="education.date"
              no-title
              color="info"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="menu = false">Cancelar</v-btn>
              <v-btn text color="info" @click="$refs.menu.save(education.date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
          <v-text-field
            outlined
            color="info"
            v-model="education.city"
            :rules="rules"
            label="Ciudad"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" align="right">
          <v-textarea
            outlined
            v-model="education.bio"
            color="info"
            label="Descripción"
          >
          </v-textarea>
          <v-btn
            v-if="edit"
            color="error"
            class="mr-4"
            @click="cancelButton"
            small
          >
            <v-icon left>far fa-window-close</v-icon>
            Cancelar
          </v-btn>
          <span></span>
          <v-btn
            :disabled="!valid"
            color="secondary"
            class="mr-4"
            @click="addEducation"
            small
          >
            <v-icon left>{{ edit ? "far fa-edit" : "fas fa-plus" }}</v-icon>
            {{ edit ? "Update" : "Educación" }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <template>
              <v-data-table dense
                :headers="headers"
                :items="cv.listEducation"
                :hide-default-footer="true"
                class="elevation-1"
                >
                
                <template v-slot:item.nameSchool="{ item }" >
                  {{
                    item.nameSchool.length > 10
                      ? item.nameSchool.substring(0, 10) + "..."
                      : item.nameSchool
                  }}
                </template>
                <template v-slot:item.degree="{ item }" >
                  {{
                    item.degree.length > 10
                      ? item.degree.substring(0, 10) + "..."
                      : item.degree
                  }}
                </template>
                <template v-slot:item.bio="{ item }"  >
                  {{
                    item.bio.length > 10
                      ? item.bio.substring(0, 10) + "..."
                      : item.bio
                  }}
                </template>
                <template v-slot:item.date="{ item }" >
                  {{
                    item.date ? formatDate (item.date) : "..." 
                  }}
                </template>
                <template v-slot:item.city="{ item }" >
                  {{
                    item.city.length > 10
                      ? item.city.substring(0, 10) + "..."
                      : item.city
                  }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon :disabled="edit"
                    color="error"
                    @click="removeItem(item)"
                    size="100%" 
                    style="margin-right:10px"
                    > fas fa-trash-alt </v-icon>
                
                  
                  <v-icon 
                      :disabled="edit" 
                      color="warning" 
                      size="100%"  
                      @click="editEducation(item)">
                       fas fa-pen 
                  </v-icon>
                  </template>
              </v-data-table>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="white" class="mr-4" @click="goBack">
            <v-icon left dark>fas fa-arrow-left</v-icon>
            Volver
          </v-btn>

          <v-btn
            :disabled="!validNext"
            color="info"
            class="mr-4"
            @click="validate"
          >
            Siguiente
            <v-icon right>fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">Confirmar</v-card-title>
            <v-card-text
              >¿Estás seguro de que quieres eliminar esta formación?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info darken-1" text @click="dialog = false">
                Cancelar
              </v-btn>
              <v-btn
                color="error darken-1"
                text
                @click="deleteEducation(itemRemove)"
              >
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    editOj: "",
    headers:[{
            text: 'Escuela/Universidad',
            align: 'start',
            sortable: false,
            value: 'nameSchool',
            class :'header-class-table',
          },
          {
            text: 'Título',
            sortable: false,
            value: 'degree',
            class :'header-class-table',
          },
          {
            text: 'Fecha',
            sortable: false,
            value: 'date',
            class :'header-class-table',
          },
          {
            text: 'Ciudad',
            sortable: false,
            value: 'city',
            class :'header-class-table',
          },
          {
            text: 'Descripción',
            sortable: false,
            value: 'bio',
            class :'header-class-table',
          },
          {
            text: 'Acciones',
            sortable: false,
            value: 'actions',
            class :'header-class-table',
          },
          
          ],
    menu: false,
    modal: false,
    menu2: false,
    valid: true,
    itemRemove: "",
    dialog: false,
    edit: false,
    validNext: true,
    education: {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nameSchool: "",
      degree: "",
      city: "",
      bio: "",
    },
    rules: [
      (v) => !!v || "Es un campo obligatorio",
      (v) => (v && v.length > 2) || "El nombre debe contener más de 2 caracteres",
    ],
  }),
  computed: {
    ...mapState(["cv"]),
    computedDateFormatted () {
      return this.formatDate(this.education.date)
    },
  },
  methods: {
    ...mapActions([
      "setBackStep",
      "setNextStep",
      "setEducation",
      "removeEducation",
    ]),
    isValid() {
      this.valid = ( this.education.nameSchool !== "" && this.education.degree !== "" && this.education.city !== "" ) ? true: false;
      // if (
      //   this.education.nameSchool !== "" &&
      //   this.education.degree !== "" &&
      //   this.education.city !== ""
      // ) {
      //   this.valid = true;
      // } else {
      //   this.valid = false;
      // }
    },
    removeItem(item) {
      this.dialog = true;
      this.itemRemove = item;
    },
    deleteEducation(item) {
      this.removeEducation(item);
      this.dialog = false;
    },
    editEducation(item) {
      this.edit = true;
      this.editOj = item;
      this.education = JSON.parse(JSON.stringify(item));
    },
    cancelButton() {
      this.education = {
        date: new Date().toISOString().substr(0, 10),
        nameSchool: "",
        degree: "",
        city: "",
        bio: "",
      };
      this.$refs.form.resetValidation();
      this.valid = false;
      this.edit = false;
    },
    addEducation() {
      this.isValid();
      if (this.valid) {
        if (this.edit) {
          this.deleteEducation(this.editOj);
        }
        this.validNext = true;
        this.edit = false;
        this.setEducation(this.education);
        this.$refs.form.resetValidation();
        this.education = {
          date: new Date().toISOString().substr(0, 10),
          nameSchool: "",
          degree: "",
          city: "",
          bio: "",
        };
      } else {
        this.$refs.form.validate();
      }
    },
    goBack() {
      this.setBackStep();
    },
    validate() {
      if (this.cv.listEducation.length > 0) {
        this.validNext = true;
        this.setNextStep();
      } else {
        this.validNext = false;
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped>
  h1 { font-size: 3em;}
  p { font-size: 1.1em; margin-bottom: 3em;}
</style>
