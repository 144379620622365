<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
          :class="title_steper_size"
        >Contacto</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 2"
          step="2"
          :class="title_steper_size"
        >Experiencia</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 3"
          step="3"
          :class="title_steper_size"
        >Formación</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 4"
          step="4"
          :class="title_steper_size"
        >Aptitudes</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 5" step="5" :class="title_steper_size">Bio</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="6" :class="title_steper_size">Terminar</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <contact></contact>
        </v-stepper-content>

        <v-stepper-content step="2">
          <experience></experience>
        </v-stepper-content>

        <v-stepper-content step="3">
          <education></education>
        </v-stepper-content>

        <v-stepper-content step="4">
          <skill></skill>
        </v-stepper-content>

        <v-stepper-content step="5">
          <bio></bio>
        </v-stepper-content>
        <v-progress-linear :indeterminate="loader" :active="loader" color="blue"></v-progress-linear>
        <v-stepper-content step="6">
          <resumen></resumen>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Bio from "./Bio";
import Contact from "./Contact";
import Education from "./Education";
import Experience from "./Experience.vue";
import Resumen from "./Resumen.vue";
import Skill from "./Skill.vue";
import utilities from "../services/utilities"

export default {
  components: { Contact, Experience, Education, Skill, Bio, Resumen },
  name: "Panel1",
  data() {
    return {
      
    };
  },
 
  computed: {
    ...mapState(["step","loader"]),

    title_steper_size(){
      return utilities.getDeviceTextType(this);
    }
    
  },
};
</script>

<style lang="scss" scoped>
  v-stepper-step {
    color: $primary;
  }
</style>
